define('redirectUsingPostService',['angular'], function () {

    var moduleDependencies = [];
    var redirectUsingPostServiceModule = angular.module('Services.redirectUsingPostService', moduleDependencies);

    redirectUsingPostServiceModule.factory('redirectUsingPostService', ['$window', 'authenticationStoreService', '$location', function ($window, authenticationStoreService, $location) {
        var mapToPageUrl = function (pageCode) {
            return pageCode === 'sfa' ? '/secondFactorAuth' :
                pageCode === 'do' ? '/displayOrg' :
                    pageCode === 'ib' ? '/' :
                        pageCode === 'lo' ? '/logout' :
                            pageCode === 'alo' ? '/authenticationLogout' : '';
        };

        var redirect = function (redirectTo) {
            redirectWithToken(redirectTo, authenticationStoreService.getCIdToken());
        };

        var redirectWithToken = function (redirectTo, token) {
            var pageUrl = mapToPageUrl(redirectTo);
            if (pageUrl && token) {
                if ($location.search().redirectUrl) {
                    try {
                        var decodedUrl = encryptWithXor(atob($location.search().redirectUrl), $location.host());
                        if (decodedUrl.indexOf('http') === 0) {
                            sessionStorage.setItem("redirectUrl", decodedUrl);
                        }
                    } catch (error) {
                        console.log('Could not decode redirect url', error);
                    }
                }
                if (sessionStorage.getItem("redirectUrl") && redirectTo === "ib") {
                    pageUrl = sessionStorage.getItem("redirectUrl");
                }
                var form = angular.element('<form target="_self" method="post"><input type="hidden" name="cIdToken" /></form>');
                form.attr('action', pageUrl);
                form.find('input').val(token);
                angular.element('body').append(form);
                form.submit();
                form.remove();
            } else {
                $window.location = '/login?message=440';
            }
        };

        var download = function (downloadFrom) {
            if (downloadFrom && authenticationStoreService.getCIdToken()) {
                var form = angular.element('<form action="" target="_self" method="post"><input type="hidden" name="cIdToken" /></form>');
                form.attr('action', downloadFrom);
                form.find('input').val(authenticationStoreService.getCIdToken());
                angular.element('body').append(form);
                form.submit();
                form.remove();
            } else {
                $window.location = '/login?message=440';
            }
        };

        var encryptWithXor = function (text, key) {
            var result = [];
            for (var i = 0; i < text.length; i++) {
                result.push(String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length)));
            }
            return result.join('');
        };

        return {
            redirect: redirect,
            redirectWithToken: redirectWithToken,
            download: download
        };
    }]);
});

