define('fpUtilities',[ 'angular' ], function() {

	var dependencies = [];

	var userStateModule = angular.module('Services.FPUtilities', dependencies);

	userStateModule.factory('fpUtilities', [ function() {
		var ALWAYS_TRUE = function() {
			return true;
		},
		
		ALWAYS_FALSE = function() {
			return false;
		},
		
		getSubscriptionManager = function() {
			var listeners = [],
			
			subscribe = function(fn) {
				listeners.push(fn);
				return function() {
					unsubscribe(fn);
				}
			},
			
			unsubscribe = function(fn) {
				var index = listeners.indexOf(fn);
				index !== -1 && listeners.splice(index, 1);
			},
			
			notify = function() {
				var args = arguments;
				listeners
					.forEach(function(listener){
						listener.apply(this, args);
					});
			};
			
			return {
				subscribe: subscribe,
				unsubscribe: unsubscribe,
				notify: notify
			};
		};

		var IfModified = function(initialState) {
			var self = {},
			_value = initialState;

			self.set = function(value) {
				var isModified = false;
				if(_value !== value) {
					_value = value;
					isModified = true;
				}
				return isModified;
			};

			self.get = function() {
				return _value;
			};

			return self;
		};
		
		return {
			ALWAYS_TRUE: ALWAYS_TRUE,
			ALWAYS_FALSE: ALWAYS_FALSE,
			getSubscriptionManager: getSubscriptionManager,
			IfModified: IfModified
		};
	} ]);
});
