define('authenticationStoreService',['angular', 'fpUtilities'], function () {

    var moduleDependencies = ['Services.FPUtilities'];
    var authenticationModule = angular.module('Services.authenticationStoreService', moduleDependencies);

    authenticationModule.factory('authenticationStoreService', ['$window', 'fpUtilities', function ($window, fpUtilities) {
        var CID_TOKEN_KEY = 'cIdToken';
        var MAX_INACTIVE_TIME_KEY = 'maxInactiveTime';
        var EXPIRATION_TIME_KEY = 'expirationTime';
        var USER_NAME_KEY = 'loggedInUserName';
        var TOKEN_LAST_UPDATED_ON_KEY = 'tokenLastUpdatedOn';
        var SECOND_FACTOR_AUTHENTICATED_KEY = 'isSecondFactorAuthVerified';
        var LAST_ACTIVITY_TIME_KEY = 'lastActiveTime';

        var DEFAULT_TOKEN_EXPIRY = 900000;
        var DEFAULT_MAX_INACTIVITY = 900000;
        var TOKEN_REFRESH_BUFFER = 60000;

        var tokenIfModified = fpUtilities.IfModified(localStorage.getItem(CID_TOKEN_KEY));
        var userNameIfModified = fpUtilities.IfModified(localStorage.getItem(USER_NAME_KEY));

        var consoleLog = function (msg) {
            console.log("authenticationStoreService:" + msg);
        };

        var tokenChangeSubscriptionManager = fpUtilities.getSubscriptionManager();

        var onStorageChange = function (e) {
            consoleLog('onStorageChange ' + e.key);

            forcedLogoutIfUserChanged(e);

            if (e.key === CID_TOKEN_KEY) {
                var token = getCIdToken();
                tokenIfModified.set(token) && (token ? tokenChangedByOtherInstanceHandler(e.newValue) : doForcedLogout());
            }

            if (e.key === null) {
                invalidate();
            }
        };

        $window.addEventListener('storage', onStorageChange);

        var forcedLogoutIfUserChanged = function (storageEvent) {
            if (storageEvent.key !== USER_NAME_KEY) return;

            if (userNameIfModified.set(storageEvent.newValue)) {
                consoleLog('forcedLogoutOnUserChanged Old:' + storageEvent.oldValue + ' New:' + storageEvent.newValue);
                invalidate();
                $window.location = "/login?message=441";
            }
        };

        var doForcedLogout = function () {
            consoleLog('doForcedLogout');
            invalidate();
            $window.location = "/login?message=441";
        };

        var tokenChangedByOtherInstanceHandler = function (newToken) {
            sessionStorage.setItem(CID_TOKEN_KEY, newToken);
            tokenChangeSubscriptionManager.notify(newToken);
        };

        var now = function () {
            return new Date().getTime();
        };

        var setInLocalAndSessionStoarge = function (key, value) {
            localStorage.setItem(key, value);
            sessionStorage.setItem(key, value);
        };

        var removeFromLocalAndSessionStoarge = function (key) {
            localStorage.removeItem(key);
            sessionStorage.removeItem(key);
        };

        var getCIdToken = function () {
            return localStorage.getItem(CID_TOKEN_KEY);
        };

        var getAuthenticationToken = function () {
            return 'Bearer ' + getCIdToken();
        };

        var setCIdToken = function (token) {
            tokenIfModified.set(token);
            setInLocalAndSessionStoarge(CID_TOKEN_KEY, token);
            localStorage.setItem(TOKEN_LAST_UPDATED_ON_KEY, now());

            tokenChangeSubscriptionManager.notify(token);
        };

        var getMaxInactiveTime = function () {
            return parseInt(localStorage.getItem(MAX_INACTIVE_TIME_KEY) || DEFAULT_MAX_INACTIVITY);
        };

        var setMaxInactiveTime = function (maxInactiveTime) {
            setInLocalAndSessionStoarge(MAX_INACTIVE_TIME_KEY, maxInactiveTime * 1000);
        };

        var getExpirationTime = function () {
            return parseInt(localStorage.getItem(EXPIRATION_TIME_KEY)) || DEFAULT_TOKEN_EXPIRY;
        };

        var setExpirationTime = function (expirationTime) {
            setInLocalAndSessionStoarge(EXPIRATION_TIME_KEY, expirationTime * 1000);
        };

        var setAuthDataOnLogin = function (authData) {
            setCIdToken(authData.cIdToken);
            setMaxInactiveTime(authData.maxInactiveTime);
            setExpirationTime(authData.expirationTime);
            localStorage.setItem(LAST_ACTIVITY_TIME_KEY, new Date().getTime());
        };

        var invalidate = function () {
            tokenIfModified.set(undefined);
            userNameIfModified.set(undefined);
            removeFromLocalAndSessionStoarge(CID_TOKEN_KEY);
            removeFromLocalAndSessionStoarge(MAX_INACTIVE_TIME_KEY);
            removeFromLocalAndSessionStoarge(EXPIRATION_TIME_KEY);
            removeFromLocalAndSessionStoarge(USER_NAME_KEY);
            removeFromLocalAndSessionStoarge(TOKEN_LAST_UPDATED_ON_KEY);

            localStorage.removeItem(SECOND_FACTOR_AUTHENTICATED_KEY);

            $window.removeEventListener('storage', onStorageChange);
        };

        var getTokenLastUpdatedOn = function () {
            return parseInt(localStorage.getItem(TOKEN_LAST_UPDATED_ON_KEY));
        };

        var getTokenRefreshDuration = function () {
            return Math.max(getTokenLastUpdatedOn() + getExpirationTime() - TOKEN_REFRESH_BUFFER - now(), 0);
        };

        var isOtherUserLoggedInToAnotherTab = function () {
            if (!sessionStorage.getItem(USER_NAME_KEY)) {
                sessionStorage.setItem(USER_NAME_KEY, localStorage.getItem(USER_NAME_KEY));
            }
            return sessionStorage.getItem(USER_NAME_KEY) !== localStorage.getItem(USER_NAME_KEY);
        };

        var setLoggedInUserName = function (userName) {
            userNameIfModified.set(userName);
            setInLocalAndSessionStoarge(USER_NAME_KEY, userName);
        };

        var restorePreAuthenticatedSession = function () {
            var token = localStorage.getItem(CID_TOKEN_KEY);
            tokenIfModified.set(token);
            sessionStorage.setItem(CID_TOKEN_KEY, token);
            sessionStorage.setItem(MAX_INACTIVE_TIME_KEY, localStorage.getItem(MAX_INACTIVE_TIME_KEY));
            sessionStorage.setItem(TOKEN_LAST_UPDATED_ON_KEY, localStorage.getItem(TOKEN_LAST_UPDATED_ON_KEY));
            sessionStorage.setItem(EXPIRATION_TIME_KEY, localStorage.getItem(EXPIRATION_TIME_KEY));
            sessionStorage.setItem(USER_NAME_KEY, localStorage.getItem(USER_NAME_KEY));
        };

        var isTokenRefreshed = function () {
            return getTokenRefreshDuration() > TOKEN_REFRESH_BUFFER;
        };

        var isTokenExpired = function () {
            return now() - getTokenLastUpdatedOn() > getExpirationTime();
        };

        //TODO: Need to revisit - after refactoring, should be removed
        var clearSessionStorageExceptAuthKeys = function () {
            for (var keyIndex = 0; keyIndex < sessionStorage.length; keyIndex++) {
                var key = sessionStorage.key(keyIndex);

                if (key !== CID_TOKEN_KEY
                    && key !== MAX_INACTIVE_TIME_KEY
                    && key !== TOKEN_LAST_UPDATED_ON_KEY
                    && key !== EXPIRATION_TIME_KEY
                    && key !== USER_NAME_KEY) {
                    sessionStorage.removeItem(key);
                }
            }
        };

        var secondFactorAuthenticated = function () {
            localStorage.setItem(SECOND_FACTOR_AUTHENTICATED_KEY, true);
        };

        var isSecondFactorAuthenticated = function () {
            return !!localStorage.getItem(SECOND_FACTOR_AUTHENTICATED_KEY);
        };

        return {
            getCIdToken: getCIdToken,
            getAuthenticationToken: getAuthenticationToken,
            setCIdToken: setCIdToken,

            getMaxInactiveTime: getMaxInactiveTime,
            setMaxInactiveTime: setMaxInactiveTime,

            getExpirationTime: getExpirationTime,
            setExpirationTime: setExpirationTime,

            setLoggedInUserName: setLoggedInUserName,

            setAuthDataOnLogin: setAuthDataOnLogin,

            invalidate: invalidate,

            subscribeTokenChange: tokenChangeSubscriptionManager.subscribe,
            unsubscribeTokenChange: tokenChangeSubscriptionManager.unsubscribe,

            getTokenRefreshDuration: getTokenRefreshDuration,
            isTokenRefreshed: isTokenRefreshed,
            isTokenExpired: isTokenExpired,

            isOtherUserLoggedInToAnotherTab: isOtherUserLoggedInToAnotherTab,

            restorePreAuthenticatedSession: restorePreAuthenticatedSession,

            clearSessionStorageExceptAuthKeys: clearSessionStorageExceptAuthKeys,

            secondFactorAuthenticated: secondFactorAuthenticated,
            isSecondFactorAuthenticated: isSecondFactorAuthenticated
        };
    }]);
});
